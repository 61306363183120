import { useEffect, useRef } from "react";

const TradingViewWidget = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        
        script.async = true;
        script.innerHTML = JSON.stringify({
            "width": "100%",
            "height": "500",
            "symbol": "BINANCE:BTCUSDT", 
            "interval": "H",
            "timezone": "Etc/UTC",
            "theme": "dark",
            "style": "1",
            "locale": "en",
            "enable_publishing": false,
            "allow_symbol_change": true,
            "frameElementId": "tradingview_2c135",  // Additional options
            "hide_side_toolbar": "0",
            "save_image": "1",
            "details": "1",
            "studies": "BB@tv-basicstudies\u001fVolume@tv-basicstudies\u001fVWAP@tv-basicstudies",
            "studies_overrides": "{}",
            "utm_source": "antleverage.com",
            "utm_medium": "widget",
            "utm_campaign": "chart",
            "utm_term": "COINBASE:BTCUSD",
            "page-uri": "antleverage.com/"
        });
        containerRef.current.appendChild(script);
    }, []);

    return (
        <div style={styles.container}>
            <div ref={containerRef} style={styles.widget} />
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#121212",
    },
    widget: {
        width: "100%",
        height: "500px",
    },
};

export default TradingViewWidget;
