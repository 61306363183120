const NotAuthorizedPage = () => {
    return (
      <div className="text-center text-white">
        <h1>403 - Not Authorized</h1>
        <p>You do not have permission to access this page.</p>
      </div>
    );
  };
  
  export default NotAuthorizedPage; // Make sure it's exported as default
  