import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, ListGroup, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { useUser } from './UserContext';
import './ProfilePage.css'; // Import custom CSS for theming
import Compressor from 'compressorjs';

const ProfilePage = () => {
    const { user } = useUser();
    const [balance, setBalance] = useState(0.00);
    const [leverage, setLeverage] = useState(0.00);
    const [fundingBalance, setFundingBalance] = useState(0.00);
    const [frenchBalance, setFrenchBalance] = useState(0.00);
    const [tradingHistory, setTradingHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // For pagination
    const [itemsPerPage] = useState(5); // Number of items per page
    const [totalItems, setTotalItems] = useState(0); // Total number of items
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [transferAmount, setTransferAmount] = useState(0);
    const [transferType, setTransferType] = useState('balanceToLeverage');
    const [paymentAddress, setPaymentAddress] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [selfies, setSelfies] = useState([]); // Store multiple selfies

    useEffect(() => {
        if (!user) return;

        const fetchProfileData = async () => {
            try {
                const response = await axios.get(`https://antleverage.com/api/profile/${user.id}`);
                setBalance(Number(response.data.balance) || 0.00);
                setLeverage(Number(response.data.leverage) || 0.00);
                setFundingBalance(Number(response.data.funding_balance) || 0.00);
                setFrenchBalance(Number(response.data.french_balance) || 0.00);
                setTradingHistory(response.data.tradingHistory || []);  // Ensure it's set to an empty array if none
                setTotalItems(response.data.tradingHistory.length || 0); // Set total items
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchProfileData();
    }, [user]);

    // Handle Fund Transfers
    const handleTransfer = async () => {
        let endpoint;
        switch (transferType) {
            case 'balanceToLeverage': endpoint = '/api/transfer/balance-to-leverage'; break;
            case 'leverageToFunding': endpoint = '/api/transfer/leverage-to-funding'; break;
            case 'fundingToFrench': endpoint = '/api/transfer/funding-to-french'; break;
            case 'frenchToFunding': endpoint = '/api/transfer/french-to-funding'; break;
            case 'fundingToLeverage': endpoint = '/api/transfer/funding-to-leverage'; break;
            case 'leverageToBalance': endpoint = '/api/transfer/leverage-to-balance'; break;
            default: return;
        }

        try {
            const response = await axios.post(`https://antleverage.com${endpoint}`, {
                userId: user.id,
                amount: transferAmount,
                ...(transferType === 'fundingToFrench' && { paymentAddress }),
            });

            setBalance(Number(response.data.balance) || balance);
            setLeverage(Number(response.data.leverage) || leverage);
            setFundingBalance(Number(response.data.funding_balance) || fundingBalance);
            setFrenchBalance(Number(response.data.french_balance) || frenchBalance);
            setShowTransferModal(false);
            alert('Transfer successful!');
        } catch (error) {
            alert('Error during transfer. Please contact support.');
            console.error('Transfer error:', error);
        }
    };

    // Handle Password Change
    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            alert('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post('/api/change-password', {
                userId: user.id,
                currentPassword,
                newPassword,
            });

            if (response.status === 200) {
                alert('Password updated successfully.');
                setShowPasswordModal(false);
            }
        } catch (error) {
            alert('Error updating password. Please try again.');
            console.error('Change password error:', error);
        }
    };

    // Capture Selfie for Verification
    const captureSelfie = (event) => {
        const selectedFiles = event.target.files;
        if (selectedFiles.length === 3) {
            setSelfies(selectedFiles); // Store 3 files
        } else {
            alert("Please select exactly three files.");
        }
    };

    

    const handleVerify = async () => {
        if (!selfies || selfies.length !== 3) {
            alert("Please upload exactly three files for verification.");
            return;
        }

        const files = [selfies[0], selfies[1], selfies[2]];
        const failedUploads = [];

        const compressImage = (file) => {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: 0.8, // Adjust quality to compress the image
                    maxWidth: 1000, // Limit the width of the image
                    maxHeight: 1000, // Limit the height of the image
                    success: (compressedFile) => resolve(compressedFile),
                    error: (err) => reject(err),
                });
            });
        };

        for (let i = 0; i < files.length; i++) {
            try {
                // Compress the image
                const compressedFile = await compressImage(files[i]);

                if (compressedFile.size > 1 * 1024 * 1024) {
                    failedUploads.push(`File ${i + 1} (${files[i].name}) exceeds 1MB after compression.`);
                    continue;
                }

                const formData = new FormData();
                formData.append("senderId", user.id);
                formData.append("recipientId", 1);
                formData.append("message", "Verification");
                formData.append("file", compressedFile);

                // Upload the compressed image
                await axios.post("https://antleverage.com/api/chat", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
            } catch (error) {
                failedUploads.push(`File ${i + 1} (${files[i].name})`);
            }
        }

        if (failedUploads.length > 0) {
            alert(`The following files failed to upload:\n${failedUploads.join("\n")}. Please try uploading smaller image files or contact customer service for assistance.`);
        } else {
            alert("All verification files were uploaded successfully!");
            setShowVerifyModal(false);
        }
    };


    // Pagination logic
    const currentTrades = () => {
        const indexOfLastTrade = currentPage * itemsPerPage;
        const indexOfFirstTrade = indexOfLastTrade - itemsPerPage;
        return tradingHistory.slice(indexOfFirstTrade, indexOfLastTrade);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    if (!user) {
        return <p className='glassy-message'>Please log in to view your profile.</p>;
    }

    return (
        <div className="profile-page">
            <Container className="quick-recharge-container py-5">
                <div className="quick-recharge-card">
                    <div className="quick-recharge-card-body">
                        <h2 className="quick-recharge-h2 text-center mb-4">Welcome, {user.name}</h2>
                        {/* Verification Status */}
                        <div className="text-center mb-3">
                            {user.verified ? (
                                <>
                                    <p className="bg-light p-2 m-3"><strong className="text-success m-1">✅ Verified</strong></p>
                                    <Button className="custom-btn m-2" onClick={() => setShowTransferModal(true)}>Transfer Funds</Button>
                                    <Button variant="secondary" className="custom-btn m-2" onClick={() => setShowPasswordModal(true)}>Change Password</Button>
                                </>
                            ) : (
                                <Button variant="warning" onClick={() => setShowVerifyModal(true)}>Verify</Button>
                            )}
                        </div>

                        <div className="balances text-right mb-4 text-white">
                            <p><strong>Balance:</strong> {balance.toFixed(2)} USDT</p>
                            <p><strong>Leverage:</strong> {leverage.toFixed(2)} USDT</p>
                            <p><strong>Funding Balance:</strong> {fundingBalance.toFixed(2)} USDT</p>
                            <p><strong>French Balance:</strong> {frenchBalance.toFixed(2)} USDT</p>
                        </div>

                        <h3 className="text-center mt-4 text-white">Trading History</h3>
                        <ListGroup variant="flush">
                            {currentTrades().length > 0 ? (
                                currentTrades().map((trade, index) => (
                                    <ListGroup.Item
                                        key={index}
                                        className={`trade-item ${trade.trade_type === 'buy' ? 'buy' : 'sell'}`}
                                    >
                                        <Row>
                                            <Col><strong>{trade.trade_type}</strong></Col>
                                            <Col className="text-end">{Number(trade.amount).toFixed(2)} USDT</Col>
                                            <Col className="text-end">{new Date(trade.trade_date).toLocaleString()}</Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <p className="text-center">No trading history available.</p>
                            )}
                        </ListGroup>

                        {/* Pagination Controls */}
                        <div className="pagination-controls text-center mt-4">
                            <Button
                                variant="secondary"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </Button>
                            <span className="mx-3 text-dark">Page {currentPage} of {totalPages}</span>
                            <Button
                                variant="secondary"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>

            {/* Verification Modal */}
            <Modal show={showVerifyModal} onHide={() => setShowVerifyModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Verification Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="fileUpload">
                            <Form.Label>Select 3 files for verification
                                <br /> (Selfie, ID Front-side and Back-side images)</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={captureSelfie}
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={handleVerify}>
                            Submit for Verification
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Transfer Funds Modal */}
            <Modal show={showTransferModal} onHide={() => setShowTransferModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Fund Transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="transferAmount">
                            <Form.Label>Amount to Transfer</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter amount"
                                value={transferAmount}
                                onChange={(e) => setTransferAmount(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="transferType">
                            <Form.Label>Transfer Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={transferType}
                                onChange={(e) => setTransferType(e.target.value)}
                            >
                                <option value="balanceToLeverage">Balance to Leverage</option>
                                <option value="leverageToFunding">Leverage to Funding</option>
                                <option value="fundingToFrench">Funding to French</option>
                                <option value="frenchToFunding">French to Funding</option>
                                <option value="fundingToLeverage">Funding to Leverage</option>
                                <option value="leverageToBalance">Leverage to Balance</option>
                            </Form.Control>
                        </Form.Group>
                        {transferType === 'fundingToFrench' && (
                            <Form.Group controlId="paymentAddress">
                                <Form.Label>Payment Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter payment address"
                                    value={paymentAddress}
                                    onChange={(e) => setPaymentAddress(e.target.value)}
                                />
                            </Form.Group>
                        )}
                    </Form>
                    <Button variant="primary" onClick={handleTransfer}>
                        Submit Transfer
                    </Button>
                </Modal.Body>
            </Modal>

            {/* Change Password Modal */}
            <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="currentPassword">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter current password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="newPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter new password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm new password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                    <Button variant="primary" onClick={handleChangePassword}>
                        Change Password
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ProfilePage;
