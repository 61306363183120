import React from 'react';

const Terms = () => {
    return (
        <div className="terms-section py-5 text-white">
            <div className="container">
                <h2 className="mb-4">Terms of Service</h2>

                <h4>1. Acceptance of Terms</h4>
                <p>
                    By accessing or using the Ant trading platform (the "Platform"), provided by Antinc ("Antinc," "we," "us," or "our"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not access or use the Platform. These Terms constitute a legally binding agreement between you and Antinc.
                </p>

                <h4>2. Account Eligibility</h4>
                <p>To use the Platform, you must be:</p>
                <ul>
                    <li>At least 18 years of age;</li>
                    <li>Legally competent to enter into a binding contract;</li>
                    <li>Not a resident of a jurisdiction where the Platform's operation is prohibited by law; and</li>
                    <li>Not subject to any sanctions imposed by the United States or other relevant jurisdictions.</li>
                </ul>
                <p>Antinc reserves the right to refuse service to anyone at any time for any reason.</p>

                <h4>3. Account Registration and Security</h4>
                <p>
                    You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to immediately notify Antinc of any unauthorized use of your account or any other breach of security. Antinc is not liable for any losses incurred due to unauthorized access to your account, except where such loss is due to Antinc’s gross negligence or willful misconduct.
                </p>

                <h4>4. Trading Activities</h4>
                <p><strong>Risk Disclosure:</strong> Trading involves significant risk of loss. You acknowledge that you understand the risks involved and are solely responsible for your trading decisions. You should not invest more than you can afford to lose.</p>
                <p><strong>Prohibited Activities:</strong> You agree not to engage in any prohibited activities on the Platform, including but not limited to market manipulation, spoofing, layering, wash trading, and any other activity that violates applicable laws or regulations.</p>
                <p><strong>Platform Use:</strong> You agree to use the Platform only for legitimate trading purposes. Any misuse of the Platform, including but not limited to unauthorized access, data scraping, or attempts to circumvent security measures, is strictly prohibited.</p>
                <p><strong>Trading Rules:</strong> All trading activities are subject to Antinc's trading rules, which are incorporated herein by reference and may be updated from time to time.</p>

                <h4>5. Fees and Charges</h4>
                <p>
                    Antinc may charge fees for using the Platform. These fees will be clearly disclosed on the Platform and may be subject to change. You agree to pay all applicable fees in a timely manner.
                </p>

                <h4>6. Intellectual Property</h4>
                <p>
                    The Platform and its content, including but not limited to software, designs, trademarks, and logos, are the intellectual property of Antinc and are protected by copyright, trademark, and other intellectual property laws. You may not use the Platform or its content without Antinc's express written consent.
                </p>

                <h4>7. Disclaimer of Warranties</h4>
                <p>
                    The Platform is provided "as is" and "as available" without warranty of any kind, either express or implied. Antinc does not guarantee the accuracy, completeness, or reliability of the Platform or its content.
                </p>

                <h4>8. Limitation of Liability</h4>
                <p>
                    To the maximum extent permitted by applicable law, Antinc shall not be liable for any indirect, incidental, consequential, special, or punitive damages arising out of or in connection with your use of the Platform.
                </p>

                <h4>9. Indemnification</h4>
                <p>
                    You agree to indemnify and hold Antinc harmless from any claims, losses, damages, liabilities, costs, or expenses arising out of or in connection with your use of the Platform or violation of these Terms.
                </p>

                <h4>10. Termination</h4>
                <p>
                    Antinc may terminate your access to the Platform at any time for any reason, including but not limited to violation of these Terms.
                </p>

                <h4>11. Governing Law</h4>
                <p>
                    These Terms shall be governed by and construed in accordance with the laws of United States of America including but not limited to the CCPA (California Consumer Privacy Act) and the GDPR (General Data Protection Regulation).
                </p>

                <h4>12. Modifications</h4>
                <p>
                    Antinc reserves the right to modify these Terms at any time. Any modifications will be effective upon posting on the Platform. Your continued use of the Platform after the posting of any modifications constitutes your acceptance of the modified Terms.
                </p>
            </div>
        </div>
    );
};

export default Terms;
