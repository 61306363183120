import React, { useEffect, useState } from 'react';
// import io from 'socket.io-client';
import { Table, Container } from 'react-bootstrap';
// import FAQSection from './FAQSection';
// import BottomCtaLogin from './BottomCtaLogin';
import './TradingData.css'; // Import your custom CSS file
import TradingViewWidget from './TradingViewWidget';
// const socket = io('https://antleverage.com/');

const TradingData = () => {
    const [data, setData] = useState([]);
    console.log(data);

    // Function to fetch data from the new API endpoint
    const fetchData = async () => {
        try {
            const response = await fetch('https://antleverage.com/api/trading-data'); // Assuming the backend is running on the same server
            const newData = await response.json();
            if (Array.isArray(newData) && newData.length > 0) {
                setData(newData);
            }
        } catch (error) {
            console.error('Error fetching trading data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch data immediately when the component mounts

        const intervalId = setInterval(fetchData, 30000); // Fetch every 30 seconds
        return () => clearInterval(intervalId); // Clean up the interval on unmount
    }, []);

    return (
        <div>


            <Container className="mt-5">

                <h2 className="text-center mb-4 text-white">Trading Graph</h2>
                {/* Add TradingView Widget Here */}
                <div className="mt-5">
                    <TradingViewWidget />
                </div>


                <h2 className="text-center mt-5 mb-4 text-white">Live Trading Data</h2>
                <Table striped bordered hover className="styled-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Symbol</th>
                            <th>Price</th>
                            <th>Change</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => {
                            const backgroundColor = item.change.startsWith('+') ? '#d4edda' : '#f8d7da'; // Bootstrap success and danger colors
                            const textColor = item.change.startsWith('+') ? '#155724' : '#721c24'; // Bootstrap success and danger text colors

                            return (
                                <tr key={index} className="table-row">
                                    <td>{item.name}</td>
                                    <td>
                                        {item.symbol}/
                                        <span style={{ color: 'grey', fontSize: '12px' }}>USDT</span>
                                    </td>
                                    <td className={item.change.startsWith('+') ? 'text-success' : 'text-danger'}>
                                        {item.price}
                                    </td>
                                    <td
                                        style={{
                                            backgroundColor,
                                            color: textColor,
                                            textAlign: 'center',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {item.change}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Container>

        </div>
    );
};

export default TradingData;
