import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from './UserContext'; // Assuming UserContext provides logged-in user data
import './ChatComponent.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Compressor from 'compressorjs';

const Chat = () => {
    const { user } = useUser(); // Fetching the logged-in user's info
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [file, setFile] = useState(null);
    const [addresses, setAddresses] = useState([]); // State for fetched addresses
    const [showFAQ, setShowFAQ] = useState(false); // Control FAQ visibility

    const faq = [
        {
            question: "Why can’t my balance be transferred because it is frozen?",
            answer: "Hello, if you have an order in progress, such as withdrawal, and the order has not been closed, it will be normal after other orders in progress are completed!"
        },
        {
            question: "How to withdraw and recharge?",
            answer: "Hello! Log in to your registered account, click on assets, and select the cash withdrawal and recharge button below USDT! Follow the prompts to complete."
        },
        {
            question: "What if I forget my password and register my mailbox?",
            answer: "You can choose: forget password which will generate a new password for you and send it to your registered mailbox."
        },
        {
            question: "Where is my balance?",
            answer: "Click on profile, find your corresponding currency, and you can check your balance."
        },
        {
            question: "How to register?",
            answer: "Click on the login menu navigation item and click sign up on the login page."
        }
    ];

    useEffect(() => {
        if (user && user.id) {
            axios.get(`https://antleverage.com/api/chat/${user.id}`)
                .then(res => setMessages(res.data))
                .catch(err => console.error(err));
        }

        // Fetch addresses
        const fetchAddresses = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/addresses');
                setAddresses(response.data);
            } catch (error) {
                console.error('Error fetching addresses:', error);
            }
        };

        fetchAddresses();
    }, [user]);

    const handleSendMessage = async () => {

        // Compress the file if it's an image and larger than 1MB
        const compressImage = (file) => {
            return new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: 0.8, // Adjust quality to compress the image
                    maxWidth: 1000, // Limit the width of the image
                    maxHeight: 1000, // Limit the height of the image
                    success: (compressedFile) => resolve(compressedFile),
                    error: (err) => reject(err),
                });
            });
        };
        
        let compressedFile = file;

        if (file && file.size > 1 * 1024 * 1024) {
            try {
                // Compress the file before sending
                compressedFile = await compressImage(file);
                if (compressedFile.size > 1 * 1024 * 1024) {
                    alert("The compressed file is still too large. Please upload a smaller image.");
                    return;
                }
            } catch (error) {
                console.error("Image compression failed:", error);
                alert("Failed to compress the image. Please try uploading a smaller image.");
                return;
            }
        }

        const formData = new FormData();
        formData.append('senderId', user.id);
        formData.append('recipientId', 1); // Change this to the actual recipient ID
        formData.append('message', newMessage);
        if (compressedFile) formData.append('file', compressedFile);

        try {
            const res = await axios.post('https://antleverage.com/api/chat', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setMessages([...messages, res.data]);
            setNewMessage('');
            setFile(null);
            setShowFAQ(true); // Show FAQ options after sending a message
            alert("Message sent successfully!");
        } catch (err) {
            console.error("Error sending message:", err);
            alert("Failed to send the message. Please try again.");
        }
    };



    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            setFile(selectedFile);
        } else {
            alert('Only image files are allowed');
        }
    };

    const handleFAQClick = (faqAnswer) => {
        const faqMessage = {
            sender_id: null, // Null to indicate it's a system-generated message
            sender_name: "System",
            message: faqAnswer,
            timestamp: new Date().toISOString(),
        };
        setMessages([...messages, faqMessage]);
        setShowFAQ(false); // Hide FAQ after selecting an option
    };

    return (
        <div className="container chat-container text-white p-4 rounded">
            {/* Addresses Section */}
            <div className="addresses-section border border-secondary p-3 mb-3 rounded">
                <h5 className="text-info">Available Addresses</h5>
                {addresses.length > 0 ? (
                    <ul>
                        {addresses.map((item) => (
                            <li key={item.option}>
                                <strong>{item.option}:</strong> {item.address}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-muted">No addresses available.</p>
                )}
            </div>

            {/* Chat Messages Section */}
            <div className="chat-messages border border-secondary p-3 mb-3 rounded bg-secondary">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`mb-2 p-2 rounded ${msg.sender_id === user.id ? "message-sent text-white align-self-end" : "message-received text-dark align-self-start"}`}
                    >
                        <div className="message-info">
                            <strong>{msg.sender_id === user.id ? 'You' : msg.sender_name}:</strong>
                        </div>
                        {msg.message && <p className="message-text mb-1">{msg.message}</p>}
                        {msg.file_url && <img src={`https://antleverage.com${msg.file_url}`} alt="Attachment" className="img-fluid rounded" />}
                        <small className="text-muted d-block mt-1">{new Date(msg.timestamp).toLocaleString()}</small>
                    </div>
                ))}

                {/* FAQ Section */}
                {showFAQ && (
                    <div className="faq-options mt-3">
                        <p className="text-muted">Suggested FAQs:</p>
                        {faq.map((item, index) => (
                            <button
                                key={index}
                                className="btn btn-outline-info btn-sm m-1"
                                onClick={() => handleFAQClick(item.answer)}
                            >
                                {item.question}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            {/* Chat Input Section */}
            <div className="chat-input form-group">
                <textarea
                    className="form-control bg-light text-dark border-secondary mb-2"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message here..."
                />
                <input
                    type="file"
                    className="form-control-file mb-2 text-dark"
                    onChange={handleFileChange}
                    accept="image/*"
                />
                <button
                    className="btn btn-info w-100"
                    onClick={handleSendMessage}
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default Chat;
