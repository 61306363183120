import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Tabs, Tab, ListGroup } from 'react-bootstrap';
import { useUser } from './UserContext';
import axios from 'axios';

const TradeComponent = () => {
    const { user } = useUser();  // Get user data from context
    const [tradeType, setTradeType] = useState('buy');
    const [amount, setAmount] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [balance, setBalance] = useState(0.00);
    const [currentPrice, setCurrentPrice] = useState(0); // Store the current price
    const [priceData, setPriceData] = useState([]);
    const [isIncreasing, setIsIncreasing] = useState(true); // To control the price direction
    const [lastUpdated, setLastUpdated] = useState(null); // Store the last updated time
    const [costPrice, setCostprice] = useState(0);

    const [sessionStartPrice, setSessionStartPrice] = useState(null); // To store the start price
    const [sessionEndPrice, setSessionEndPrice] = useState(null); // To store the end price
    const [isSessionActive, setIsSessionActive] = useState(false); // To manage the session state


    useEffect(() => {
        if (!user) {
            return; // Optionally handle unauthenticated user
        }

        const fetchProfileData = async () => {
            try {
                const response = await axios.get(`https://antleverage.com/api/profile/${user.id}`);
                setBalance(Number(response.data.balance) || 0.00);
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };
        // /api/get-latest-pricesimilar
        const fetchInitialPriceData = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/get-latest-price');
                let latestPrice = Number(response.data.amount);
                const previousAmount = Number(response.data.previous_amount);
                const newAmount = Number(response.data.new_amount);
                setCostprice(latestPrice);

                // Set initial price with a small random fluctuation from previous price
                const randomPercentage = (Math.random() * 0.0003) + 0.0001; // Random percentage between 0.003% and 0.01%
                const randomDirection = Math.random() >= 0.5; // Randomly decide to rise or fall
                latestPrice = randomDirection
                    ? previousAmount * (1 + randomPercentage)
                    : previousAmount * (1 - randomPercentage);
                console.log(randomPercentage);
                setCurrentPrice(latestPrice); // Set the initial current price
                setIsIncreasing(response.data.direction === 'rise'); // Set isIncreasing based on direction
                setLastUpdated(new Date(response.data.date_updated)); // Store the last updated time
            } catch (error) {
                console.error('Error fetching price data:', error);
            }
        };

        fetchProfileData();
        fetchInitialPriceData();
    }, [user]);



    useEffect(() => {
        let isUpdating = false; // Flag to track whether an update is in progress

        const updatePrice = async () => {
            if (isUpdating) {
                return; // Prevent multiple updates at the same time
            }

            isUpdating = true; // Mark as updating

            try {
                const response = await axios.get('https://antleverage.com/api/get-latest-price');
                const latestPrice = Number(response.data.amount);
                const previousAmount = Number(response.data.previous_amount);
                const newAmount = Number(response.data.new_amount);
                const direction = response.data.direction;
                const dateUpdated = new Date(response.data.date_updated);
                const now = new Date();
                const timeElapsed = (now - dateUpdated) / 1000;

                let directionToUse = direction;
                let updatedPrice = currentPrice;

                if (timeElapsed < 1200) {
                    if (directionToUse === 'rise') {
                        updatedPrice = newAmount * (1 + (Math.random() * 0.00003));
                    } else {
                        updatedPrice = newAmount * (1 - (Math.random() * 0.00003));
                    }
                } else {
                    const randomDirection = Math.random() >= 0.5;
                    directionToUse = randomDirection ? 'rise' : 'fall';
                    const randomPercentage = Math.random() * 0.00003 + 0.001;
                    updatedPrice = directionToUse === 'rise'
                        ? newAmount * (1 + randomPercentage)
                        : newAmount * (1 - randomPercentage);
                }

                setIsIncreasing(directionToUse === 'rise');
                setCurrentPrice(updatedPrice);
                setLastUpdated(dateUpdated);

                // Perform update only if necessary
                if (timeElapsed < 1200 && (directionToUse === 'rise' && newAmount < updatedPrice || directionToUse === 'fall' && newAmount > updatedPrice)) {
                    await axios.post('https://antleverage.com/api/update-pricenew', {
                        new_amount: updatedPrice
                    });
                } else if (timeElapsed >= 1200) {
                    await axios.post('https://antleverage.com/api/update-pricenew', {
                        new_amount: updatedPrice
                    });
                }
            } catch (error) {
                console.error('Error updating price:', error);
            } finally {
                isUpdating = false; // Reset the flag after completion
            }
        };

        const priceInterval = setInterval(updatePrice, 1000);

        return () => clearInterval(priceInterval);
    }, [currentPrice]);




    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/get-latest-pricesimilar');
                const adjustedPrices = response.data; // Adjust according to your actual response structure
                setPriceData(adjustedPrices);
            } catch (error) {
                console.error('Error fetching price data:', error);
            }
        };

        fetchPrices(); // Call the async function
    }, [currentPrice, lastUpdated]); // Add dependencies if needed


    const generatePriceData = (basePrice) => {
        let prices = [];
        const now = new Date();
        const timeElapsed = (now - lastUpdated) / 1000; // Time elapsed in seconds

        let directionToUse = isIncreasing;

        if (timeElapsed > 3600) {
            // Randomize direction if more than 60 minutes have passed
            directionToUse = Math.random() >= 0.5;
        }

        // Generate 5 prices 3% less
        for (let i = 1; i <= 5; i++) {
            let adjustedPrice = basePrice * (1 - (Math.random() * 0.00003));
            prices.push({ usdt: `${adjustedPrice.toFixed(2)} USDT`, usd: `${adjustedPrice.toFixed(8)} USD`, value: adjustedPrice });
        }

        // Add the base price
        prices.push({ usdt: `${basePrice.toFixed(2)} USDT`, usd: `${basePrice.toFixed(8)} USD`, value: basePrice });

        // Generate 5 prices 3% more
        for (let i = 1; i <= 5; i++) {
            let adjustedPrice = basePrice * (1 + (Math.random() * 0.00003));
            prices.push({ usdt: `${adjustedPrice.toFixed(2)} USDT`, usd: `${adjustedPrice.toFixed(8)} USD`, value: adjustedPrice });
        }

        // Sort prices from least to greatest
        prices.sort((a, b) => a.value - b.value);

        return prices;
    };

    const handleTrade = (e) => {
        e.preventDefault();
        setShowConfirmation(true);
    };

    const handleStartSession = () => {
        setSessionStartPrice(currentPrice); // Capture current price as session start price
        setIsSessionActive(true); // Mark session as active
        setShowConfirmation(false); // Hide the confirmation button until session ends
    };

    const handleStopSession = () => {
        setSessionEndPrice(currentPrice); // Capture current price as session end price
        setIsSessionActive(false); // End the session
        setShowConfirmation(true); // Show the confirmation button for final trade
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (sessionStartPrice === null || sessionEndPrice === null) {
            alert('Please start and stop a session before confirming trade.');
            return;
        }

        try {
            // Calculate trade amount based on the session start and end prices
            const tradeAmount = parseFloat(amount);
            const priceDifference = parseFloat(sessionEndPrice) - parseFloat(sessionStartPrice);
            const profitLoss = priceDifference * tradeAmount;
            const calcprofloss = Math.abs(profitLoss);

            let updatedBalance = 0;

            if (tradeType === 'buy') {
                setBalance((prevBalance) => {
                    updatedBalance = profitLoss >= 0
                        ? parseFloat(prevBalance) + calcprofloss
                        : parseFloat(prevBalance) - calcprofloss;
                    return updatedBalance.toFixed(2);
                });
            } else if (tradeType === 'sell') {
                setBalance((prevBalance) => {

                    //change to the other way round for selling
                    // :) done 


                    updatedBalance = profitLoss >= 0
                        ? parseFloat(prevBalance) - calcprofloss
                        : parseFloat(prevBalance) + calcprofloss;
                    return updatedBalance.toFixed(2);
                });
            }

            setTimeout(async () => {
                alert(`Updated Balance: ${updatedBalance.toFixed(2)} USDT Profit/Loss Margin ${profitLoss.toFixed(2)}`);

                await axios.post('https://antleverage.com/api/trade', {
                    user_id: user.id,
                    trade_type: tradeType,
                    amount: updatedBalance.toFixed(2),
                    profit_loss: profitLoss.toFixed(2)
                });

                setAmount('');
                setShowConfirmation(false);
                setSessionStartPrice(null);
                setSessionEndPrice(null);
            }, 0);

        } catch (error) {
            // Handle the Axios error and extract the message from the API response
            if (error.response) {
                // Server responded with a status other than 2xx
                const errorMessage = error.response.data.message || 'An error occurred during the trade';
                alert(`Error: ${errorMessage}`);
            } else if (error.request) {
                // The request was made, but no response was received
                alert('No response received from the server.');
            } else {
                // Something else happened in setting up the request
                alert(`Error: ${error.message}`);
            }
            console.error('Error submitting trade:', error);
        }
    };



    if (!user) return <p className="text-center mt-5 text-white">Please log in to buy and sell.</p>;

    return (
        <Container fluid className="quick-recharge-container py-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <div className="quick-recharge-card">
                        <div className="quick-recharge-card-body">
                            <h1 className="quick-recharge-h2 text-center mb-4">Trade USD/USDT</h1>
                            <Tabs
                                id="trade-tabs"
                                activeKey={tradeType}
                                onSelect={(k) => { setTradeType(k); setShowConfirmation(false); }}
                                className="mb-3"
                                style={{ fontSize: '1.25rem' }}  // Make the tabs larger
                            >
                                <Tab
                                    eventKey="buy"
                                    title="Buy"
                                    tabClassName={`bg-success text-white rounded px-4 py-2 me-2 ${tradeType === 'buy' ? 'active-tab outline' : ''}`}
                                >
                                    <Form onSubmit={handleTrade}>
                                        <Form.Group controlId="formAmount">
                                            <Form.Label><h3>Current Best Price: {currentPrice.toFixed(2)} USDT</h3></Form.Label>
                                            <br />
                                            <Form.Label>Number of Hands</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter number of hands"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                        <br />
                                        <Form.Label><h3>Current Balance: {balance} USDT</h3></Form.Label>
                                        <br />
                                        {/* <Button className='mt-3' variant="success" size='lg' type="submit">
                                            Execute Buy
                                        </Button> */}
                                    </Form>
                                </Tab>
                                <Tab
                                    eventKey="sell"
                                    title="Sell"
                                    tabClassName={`bg-danger text-white rounded px-4 py-2 ${tradeType === 'sell' ? 'active-tab outline' : ''}`}
                                >
                                    <Form onSubmit={handleTrade}>
                                        <Form.Group controlId="formAmount">
                                            <Form.Label><h3>Current Best Price: {currentPrice.toFixed(2)} USDT</h3></Form.Label>
                                            <br />
                                            <Form.Label>Number of Hands</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter number of hands"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                        <br />
                                        <Form.Label><h3>Current Balance: {balance} USDT </h3></Form.Label>
                                        <br />
                                        {/* <Button className='mt-3' variant="danger" size='lg' type="submit">
                                            Execute Sell
                                        </Button> */}
                                    </Form>
                                </Tab>
                            </Tabs>
                            {!isSessionActive ? (
                                <Button className='mt-3' variant="success" size='lg' onClick={handleStartSession}>
                                    Start Session
                                </Button>
                            ) : (
                                <Button className='mt-3' variant="danger" size='lg' onClick={handleStopSession}>
                                    Stop Session
                                </Button>
                            )}

                            {showConfirmation && (
                                <Form onSubmit={handleSubmit}>
                                    <Button className='mt-3' variant="primary" size='lg' type="submit">
                                        Check Profit Loss Margin
                                    </Button>
                                </Form>
                            )}
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="quick-recharge-card">
                        <div className="quick-recharge-card-body">
                            <h2 className="text-center mb-4 text-white">Current USDT Prices</h2>
                            <div className='p-2 m-2'>
                                {priceData.map((data, index) => {
                                    // First 5 prices (Red, smaller font)
                                    if (index < 5) {
                                        return (
                                            <ListGroup.Item key={index} style={{ color: 'red', fontSize: '1.1rem' }}>
                                                {data.usdt} ({data.usd})
                                            </ListGroup.Item>
                                        );
                                    }

                                    // Middle price (Black, bold, larger font)
                                    if (index === 5) {
                                        return (
                                            <ListGroup.Item key={index} style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
                                                {data.usdt} ({data.usd})
                                            </ListGroup.Item>
                                        );
                                    }

                                    // Last 5 prices (Green, smaller font)
                                    return (
                                        <ListGroup.Item key={index} style={{ color: 'green', fontSize: '1.1rem' }}>
                                            {data.usdt} ({data.usd})
                                        </ListGroup.Item>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default TradeComponent;