import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

const AddressManagementPage = () => {
    const [options, setOptions] = useState(['ERC20', 'TRC20', 'BTC']);
    const [selectedOption, setSelectedOption] = useState('');
    const [address, setAddress] = useState('');
    const [status, setStatus] = useState('');
    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        // Fetch existing addresses
        const fetchAddresses = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/addresses');
                setAddresses(response.data);
            } catch (error) {
                console.error('Error fetching addresses:', error);
            }
        };

        fetchAddresses();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('https://antleverage.com/api/update-address', {
                option: selectedOption,
                address,
            });
            setStatus('Address updated successfully');
            setAddresses((prev) => [
                ...prev.filter((item) => item.option !== selectedOption),
                { option: selectedOption, address },
            ]);
        } catch (error) {
            console.error('Error updating address:', error);
            setStatus('Failed to update address');
        }
    };

    return (
        <Container fluid className="quick-recharge-container py-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <div className="quick-recharge-card">
                        <div className="quick-recharge-card-body">
                        <h1 className="quick-recharge-h2 text-center mb-4">Manage Payment Addresses</h1>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formOptionSelect">
                                    <Form.Label>Select Payment Option</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectedOption}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                        required
                                    >
                                        <option value="">Select an option</option>
                                        {options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formAddressInput" className="mt-3">
                                    <Form.Label>Enter Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Button className="mt-3" variant="primary" size="lg" type="submit">
                                    Update Address
                                </Button>
                            </Form>
                            {status && (
                                <Alert variant="info" className="mt-3">
                                    {status}
                                </Alert>
                            )}
                            <div className="mt-4">
                                <h3>Existing Addresses</h3>
                                <ul>
                                    {addresses.map((item) => (
                                        <li key={item.option}>
                                            {item.option}: {item.address}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AddressManagementPage;
