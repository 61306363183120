import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './QuickRecharge.css';

const UpdatePrice = () => {
    const [price, setPrice] = useState('');
    const [direction, setDirection] = useState('rise');
    const [status, setStatus] = useState('');
    const [currentPrice, setCurrentPrice] = useState(null);

    useEffect(() => {
        const fetchCurrentPrice = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/current-price');
                if (response.status === 200) {
                    setCurrentPrice(response.data.currentPrice);
                } else {
                    setCurrentPrice('Not available');
                }
            } catch (error) {
                console.error('Error fetching current price:', error);
                setCurrentPrice('Error fetching price');
            }
        };
        fetchCurrentPrice();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://antleverage.com/api/update-price', {
                amount: price,
                direction: direction,
            });

            if (response.status === 200) {
                setStatus('Price updated successfully');
            } else {
                setStatus('Failed to update price');
            }
        } catch (error) {
            console.error('Error updating price:', error);
            setStatus('Error updating price');
        }
    };

    return (
        <Container fluid className="quick-recharge-container py-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <div className="quick-recharge-card">
                        <div className="quick-recharge-card-body">
                            <h1 className="quick-recharge-h2 text-center mb-4">Update Price</h1>

                            {/* Display current price */}
                            <div className="form-group">
                                <label>Current Price:</label>
                                <p>{currentPrice !== null ? currentPrice : 'Loading...'}</p>
                            </div>

                            {/* Updated Form */}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formDirection">
                                    <Form.Label className="quick-recharge-label">Direction</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="quick-recharge-input"
                                        value={direction}
                                        onChange={(e) => setDirection(e.target.value)}
                                        required
                                    >
                                        <option value="rise">Rise</option>
                                        <option value="fall">Fall</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formAmount" className="mt-3">
                                    <Form.Label className="quick-recharge-label">Amount</Form.Label>
                                    <Form.Control
                                        className="quick-recharge-input"
                                        type="number"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        placeholder="Enter amount"
                                        required
                                    />
                                </Form.Group>

                                <Button type="submit" variant="success" className="quick-recharge-button mt-3">
                                    Update
                                </Button>
                            </Form>

                            {/* Status Message */}
                            {status && <p className="mt-3">{status}</p>}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default UpdatePrice;
