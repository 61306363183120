import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './glassy.css';

const ContactPage = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await axios.post('https://antleverage.com/api/contact', {
                email,
                phone,
                message
            });
            setStatus('Message sent successfully!');
        } catch (error) {
            setStatus('Failed to send message. Please try again.');
        }
    };

    return (
        <div className="contact-section section--bg py-5">
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col xl={6} lg={6} className="mb-4">
                        <div className="contact-thumb">
                            <img 
                                src="https://images.pexels.com/photos/33999/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
                                alt="Contact Us"
                                className="img-fluid rounded"
                            />
                        </div>
                    </Col>
                    <Col xl={6} lg={6} className="mb-4">
                        <div className="contact-form-area p-4 shadow rounded text-white">
                            <div className="section-header text-center mb-4">
                                <span className="title-badge display-4">$</span>
                                <h5 className="section-sub-title text-uppercase mt-2">Contact Us</h5>
                                <h2 className="section-title fw-bold">Connect and Reach Out for Seamless <span className="text-primary">Support</span></h2>
                            </div>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter Email..."
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formPhone">
                                    <Form.Label>Phone Number<span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter your phone number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formMessage">
                                    <Form.Label>Message<span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        placeholder="Write Here..."
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button type="submit" className="btn--base w-100 mt-3">
                                    <span>Send Message</span>
                                </Button>
                            </Form>
                            {status && (
                                <div className="mt-3 text-center">
                                    <p>{status}</p>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContactPage;
