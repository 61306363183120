import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';
import axios from 'axios';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState('');
    const [showForgetModal, setShowForgetModal] = useState(false);
    const [forgetEmail, setForgetEmail] = useState('');
    const { loginUser } = useUser();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://antleverage.com/api/login', {
                email,
                password,
            });
            loginUser(response.data.user); // Store user data in context
            setStatus('Login successful!');
            navigate('/profile'); // Route to /profile after successful login
        } catch (error) {
            setStatus('Login failed. Please try again.');
        }
    };

    const handleForgetPassword = async () => {
        try {
            const response = await axios.post('https://antleverage.com/api/forget-password', { email: forgetEmail });
            setStatus(response.data.message);
            setShowForgetModal(false);
        } catch (error) {
            setStatus('Error sending reset password email.');
        }
    };

    return (
        <div className="account-section py-5 text-white">
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="account-inner p-4 shadow rounded">
                            <div className="account-form-area text-center">
                                <h4 className="title mb-4">Log in and Stay Connected</h4>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter Email..."
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            className="form--control"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPassword">
                                        <Form.Control
                                            type="password"
                                            placeholder="Enter Password..."
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            className="form--control"
                                        />
                                    </Form.Group>
                                    <div className="text-end mb-3">
                                        <a href="#" onClick={() => setShowForgetModal(true)}>Forgot Password?</a>
                                    </div>
                                    <Button type="submit" className="btn--base w-100">
                                        Login Now
                                    </Button>
                                </Form>
                                {status && (
                                    <div className="mt-3">
                                        <p>{status}</p>
                                    </div>
                                )}
                                <div className="mt-4">
                                    <p>Don't Have An Account? <a href="/signup" className="account-control-btn">Register Now</a></p>
                                    {/* <p>By clicking Login you are agreeing with our <a href="/privacy-policy" target="_blank">Terms of feature</a></p> */}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal show={showForgetModal} onHide={() => setShowForgetModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formForgetEmail">
                            <Form.Label>Enter your registered email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={forgetEmail}
                                onChange={(e) => setForgetEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowForgetModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleForgetPassword}>Send Reset Password Email</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default LoginPage;
