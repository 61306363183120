import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const SignupPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!agreeTerms) {
            setStatus('You must agree to the Terms of Service and Privacy Policy.');
            return;
        }

        if (password !== confirmPassword) {
            setStatus('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post('https://antleverage.com/api/signup', {
                name,
                email,
                password
            });
            setStatus('Signup successful!');
        } catch (error) {
            setStatus('Signup failed. Please try again.');
        }
    };

    return (
        <div className="account-section py-5 text-white">
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="account-inner p-4 shadow rounded">
                            <div className="account-form-area text-center">
                                <h4 className="title mb-4">Create Your Account</h4>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formName">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Name..."
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                            className="form--control"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter Email..."
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            className="form--control"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formPassword">
                                        <Form.Control
                                            type="password"
                                            placeholder="Enter Password..."
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            className="form--control"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formConfirmPassword">
                                        <Form.Control
                                            type="password"
                                            placeholder="Confirm Password..."
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                            className="form--control"
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formAgreeTerms">
                                        <Form.Check 
                                            type="checkbox" 
                                            label={<span>I agree to the <a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a></span>} 
                                            checked={agreeTerms} 
                                            onChange={(e) => setAgreeTerms(e.target.checked)} 
                                            required
                                        />
                                    </Form.Group>
                                    <Button type="submit" className="btn--base w-100">
                                        Signup Now
                                    </Button>
                                </Form>
                                {status && (
                                    <div className="mt-3">
                                        <p>{status}</p>
                                    </div>
                                )}
                                <div className="mt-4">
                                    <p>Already have an account? <a href="/login" className="account-control-btn">Login</a></p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SignupPage;
