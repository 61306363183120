import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Privacy = () => {
    return (
        <div className="terms-section py-5 text-white">
            <div className="container">
                <h2 className="mb-4">Privacy Policy</h2>

                <h4>1. Information We Collect:</h4>
                <p>We collect several types of information from and about users of our Platform:</p>

                <h5>Personal Information:</h5>
                <p>This includes information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household. Examples include:</p>
                <ul>
                    <li><strong>Account Information:</strong> Name, email address, phone number, address, date of birth, and government-issued identification information (for verification purposes).</li>
                    <li><strong>Financial Information:</strong> Bank account details, credit card information (processed securely through a third-party payment processor), and trading activity details. We do not store full credit card details.</li>
                    <li><strong>Transaction Information:</strong> Records of your trading activities, including buy/sell orders, transaction amounts, and timestamps.</li>
                </ul>

                <h5>Non-Personal Information:</h5>
                <p>This includes information that does not directly identify you. Examples include:</p>
                <ul>
                    <li><strong>Usage Data:</strong> Information about how you use the Platform, such as your IP address, browser type, operating system, and the pages you visit.</li>
                    <li><strong>Device Information:</strong> Information about your device, such as your device ID and model.</li>
                </ul>

                <h4>2. How We Use Your Information:</h4>
                <p>We use your information for the following purposes:</p>
                <ul>
                    <li><strong>Providing and Maintaining the Platform:</strong> To provide you with access to and maintain the Platform's functionality.</li>
                    <li><strong>Processing Transactions:</strong> To process your financial transactions and manage your account.</li>
                    <li><strong>Customer Service:</strong> To respond to your inquiries and provide customer support.</li>
                    <li><strong>Security and Fraud Prevention:</strong> To detect and prevent fraudulent activities.</li>
                    <li><strong>Compliance:</strong> To comply with applicable laws and regulations.</li>
                    <li><strong>Improving the Platform:</strong> To analyze usage data and improve the Platform's design and functionality.</li>
                    <li><strong>Marketing (with consent):</strong> We may send you marketing communications about our products and services if you have opted in to receive them. You can opt out at any time.</li>
                </ul>

                <h4>3. How We Share Your Information:</h4>
                <p>We may share your information with the following third parties:</p>
                <ul>
                    <li><strong>Payment Processors:</strong> To process your financial transactions.</li>
                    <li><strong>Service Providers:</strong> With trusted third-party service providers who assist us in providing the Platform and its services (e.g., data analytics providers, IT support). These providers are contractually obligated to protect your information.</li>
                    <li><strong>Legal Authorities:</strong> We may disclose your information to legal authorities if required by law or legal process.</li>
                </ul>
                <p>We do not sell your personal information to third parties.</p>

                <h4>4. Data Security:</h4>
                <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</p>

                <h4>5. Your Rights:</h4>
                <p>You may have certain rights regarding your personal information, including the right to access, correct, delete, or restrict the processing of your information. To exercise these rights, please contact us using the contact information below.</p>

                <h4>6. Data Retention:</h4>
                <p>We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>

                <h4>7. Children's Privacy:</h4>
                <p>The Platform is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18.</p>

                <h4>8. Changes to this Privacy Policy:</h4>
                <p>We may update this Privacy Policy from time to time. Any changes will be effective upon posting on the Platform. We encourage you to review this Privacy Policy periodically.</p>
            </div>
        </div>        
    );
};

export default Privacy;
