import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import NavbarComponent from './NavbarComponent';
import Tradingdatapage from './TradingDatapage';
import Tradingdata from './TradingData';
import TradeComponent from './TradeComponent';
import ContactPage from './ContactPage';
import SignupPage from './SignupPage';
import LoginPage from './LoginiPage';
import ProfilePage from './ProfilePage';
import RoleAssignmentPage from './RoleAssignmentPage';
import AddressManagementPage from './AddressManagementPage';
import UpdateWalletPage from './UpdateWalletPage';
import UpdatePricePage from './UpdatePricePage';
import UpdateWalletID from './UpdateWalletID';
import CustomerService from './CustomerService';
import QuickRecharge from './QuickRecharge';
import UsersListPage from './UsersListPage';
import NotAuthorizedPage from './NotAuthorizedPage';
import Terms from './Terms';
import Privacy from './Privacy';
import { useUser } from './UserContext';

const App = () => {
  const { user } = useUser();

  return (
    <Router>
      <NavbarComponent />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Tradingdata />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/tradingdata" element={<Tradingdatapage />} />
        <Route path="/buysell" element={<TradeComponent />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/profile" element={<ProfilePage />} />

        {/* Admin-Only Routes */}
        {user && user.role === 'admin' ? (
          <>
            <Route path="/roles" element={<RoleAssignmentPage />} />
            <Route path="/addresses" element={<AddressManagementPage />} />
            <Route path="/update-wallet" element={<UpdateWalletPage />} />
            <Route path='/userslist' element={<UsersListPage />} />
            <Route path="/update-price" element={<UpdatePricePage />} />
          </>
        ) : (
          // Redirect to not-authorized if not an admin
          <Route path="/roles" element={<Navigate to="/not-authorized" />} />
        )}

        {/* Other routes */}
        <Route path="/update-walletid" element={<UpdateWalletID />} />
        <Route path="/customer-service" element={<CustomerService />} />
        <Route path='/quick-recharge' element={<QuickRecharge />} />
        <Route path="/not-authorized" element={<NotAuthorizedPage />} />

      </Routes>
      <footer className="custom-navbar text-white text-center py-4 mt-5">
        <p className="mb-1">&copy; {new Date().getFullYear()} <strong>Ant Leverage</strong>. All Rights Reserved.</p>
        <p className="mb-1">
          Empowering your journey through the dynamic world of cryptocurrency with ease and confidence.
        </p>
        <p className="mb-1">
          Experience smooth and intuitive trading on our platform, optimized for both <strong>mobile</strong> and <strong>desktop</strong> devices.
        </p>
        <p className="mt-3">
          <a href="/privacy-policy" className="text-white mx-2" style={{textDecoration:'none'}}>Privacy Policy</a> |
          <a href="/terms" className="text-white mx-2" style={{textDecoration:'none'}}>Terms of Service</a> |
          <a href="/contact" className="text-white mx-2" style={{textDecoration:'none'}}>Contact Us</a>
        </p>
      </footer>


    </Router>
  );
};

export default App;
