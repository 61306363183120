import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Badge } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from './UserContext';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome
import './AdminSidebar.css'; // Custom CSS for Sidebar

const NavbarComponent = () => {
    const { user, logoutUser } = useUser();
    const isAdmin = user && user.role === 'admin';
    const [unreadMessages, setUnreadMessages] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation(); // Track current route

    const fetchUnreadMessages = async () => {
        try {
            if (user && user.id) {
                const response = await fetch(`https://antleverage.com/api/chat/unread/${user.id}`);
                const data = await response.json();
                setUnreadMessages(data.unreadMessages.unreadCount);
            }
        } catch (error) {
            console.error('Error fetching unread messages:', error);
        }
    };

    useEffect(() => {
        let intervalId;
        if (user) {
            fetchUnreadMessages();
            intervalId = setInterval(fetchUnreadMessages, 3000);
        }
        return () => clearInterval(intervalId);
    }, [user]);

    // Close sidebar when the route changes
    useEffect(() => {
        setSidebarOpen(false);
    }, [location.pathname]);

    return (
        <>
            <Navbar expand="lg" sticky="top" className="custom-navbar">
                <Container>
                    <Navbar.Brand as={Link} to="/" className="text-white">
                        Ant
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link as={Link} to="/" className="text-white">Home</Nav.Link>
                            <Nav.Link as={Link} to="/contact" className="text-white">Contact</Nav.Link>
                            <Nav.Link as={Link} to="/tradingdata" className="text-white">Markets</Nav.Link>

                            {!user ? (
                                <Nav.Link as={Link} to="/login" className="text-white">Login</Nav.Link>
                            ) : (
                                <>
                                    <Nav.Link as={Link} to="/buysell" className="text-white">Trade</Nav.Link>
                                    <Nav.Link as={Link} to="/profile" className="text-white">Profile</Nav.Link>
                                    <Nav.Link as={Link} to="/customer-service" className="text-white">Customer Service</Nav.Link>
                                    <Nav.Link as={Link} to="/quick-recharge" className="text-white">Quick Recharge</Nav.Link>

                                    {/* Admin Sidebar Toggle Button */}
                                    {isAdmin && (
                                        <div className="text-white admin-btn" onClick={() => setSidebarOpen(!sidebarOpen)}>
                                            <i className="fas fa-bars"></i> Admin Menu
                                        </div>
                                    )}

                                    <Nav.Link as={Link} to="/" onClick={logoutUser} className="text-white">Logout</Nav.Link>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* Admin Sidebar */}
            {isAdmin && (
                <div className={`admin-sidebar ${sidebarOpen ? 'open' : ''}`}>
                    <button className="close-btn" onClick={() => setSidebarOpen(false)}>&times;</button>
                    <h3>Admin Menu</h3>
                    <ul>
                        <li><Link to="/update-wallet" onClick={() => setSidebarOpen(false)}>Update Wallet</Link></li>
                        <li><Link to="/userslist" onClick={() => setSidebarOpen(false)}>User List</Link></li>
                        <li><Link to="/addresses" onClick={() => setSidebarOpen(false)}>Change Address</Link></li>
                        <li><Link to="/roles" onClick={() => setSidebarOpen(false)}>Assign Roles</Link></li>
                        <li><Link to="/update-price" onClick={() => setSidebarOpen(false)}>Update Trade Prices</Link></li>
                        <li>
                            <Link to="/userslist" onClick={() => setSidebarOpen(false)}>
                                Notifications
                                {unreadMessages > 0 && <Badge variant="danger" className="ml-1">{unreadMessages}</Badge>}
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default NavbarComponent;
