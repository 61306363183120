import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';

const RoleAssignmentPage = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [role, setRole] = useState('user');
    const [status, setStatus] = useState('');

    useEffect(() => {
        // Fetch users from the database
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/users');
                setUsers(response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('https://antleverage.com/api/assign-role', {
                userId: selectedUser,
                role
            });
            setStatus('Role assigned successfully');
        } catch (error) {
            console.error('Error assigning role:', error);
            setStatus('Failed to assign role');
        }
    };

    return (
        <Container fluid className="quick-recharge-container py-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <div className="quick-recharge-card">
                        <div className="quick-recharge-card-body">
                            <h1 className="quick-recharge-h2 text-center mb-4">Assign Roles</h1>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formUserSelect">
                                    <Form.Label>Select User</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectedUser}
                                        onChange={(e) => setSelectedUser(e.target.value)}
                                        required
                                    >
                                        <option value="">Select a user</option>
                                        {users.map((user) => (
                                            <option key={user.id} value={user.id}>
                                                {user.name} ({user.email})
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formRoleSelect" className="mt-3">
                                    <Form.Label>Select Role</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        required
                                    >
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                    </Form.Control>
                                </Form.Group>

                                <Button className="mt-3" variant="primary" size="lg" type="submit">
                                    Assign Role
                                </Button>
                            </Form>
                            {status && (
                                <Alert variant="info" className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default RoleAssignmentPage;
