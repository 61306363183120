import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './QuickRecharge.css'; // Make sure to import the styles

const UpdateWalletPage = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [newBalance, setNewBalance] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://antleverage.com/api/users');
                setUsers(response.data);
            } catch (error) {
                setStatus('Failed to fetch users. Please try again.');
            }
        };

        fetchUsers();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://antleverage.com/api/update-wallet', {
                userId: selectedUser,
                newBalance: parseFloat(newBalance),
            });
            setStatus(`Update successful! New balance: ${response.data.wallet.balance}`);
        } catch (error) {
            setStatus('Failed to update wallet. Please try again.');
        }
    };

    return (
        <Container fluid className="quick-recharge-container py-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <div className="quick-recharge-card">
                        <div className="quick-recharge-card-body">
                            <h1 className="quick-recharge-h2 text-center mb-4">Update Wallet Balance</h1>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formUser">
                                    <Form.Label className="quick-recharge-label">Select User</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectedUser}
                                        onChange={(e) => setSelectedUser(e.target.value)}
                                        required
                                        className="quick-recharge-input"
                                    >
                                        <option value="">Select a user</option>
                                        {users.map(user => (
                                            <option key={user.id} value={user.id}>
                                                {user.name} ({user.id})
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="formNewBalance">
                                    <Form.Label className="quick-recharge-label">New Balance</Form.Label>
                                    <Form.Control
                                        type="number"
                                        step="0.01"
                                        placeholder="Enter new balance"
                                        value={newBalance}
                                        onChange={(e) => setNewBalance(e.target.value)}
                                        required
                                        className="quick-recharge-input"
                                    />
                                </Form.Group>
                                <Button className="quick-recharge-button mt-3" variant="primary" size="lg" type="submit">
                                    Update Balance
                                </Button>
                            </Form>
                            {status && (
                                <div className="quick-recharge-glassy-message mt-3 text-center">
                                    <p>{status}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default UpdateWalletPage;
