import React, { useState } from 'react';
import { Container, Card, Form, Button } from 'react-bootstrap';
import { useUser } from './UserContext';
import './QuickRecharge.css';

const QuickRecharge = () => {
    const { user } = useUser();
    const [quantity, setQuantity] = useState('');
    const [paymentAddress, setPaymentAddress] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Display alert message
        window.alert('Submitted successfully!');

        // Clear the form fields
        setQuantity('');
        setPaymentAddress('');
    };

    if (!user) {
        return <p className='quick-recharge-glassy-message'>Please log in to quick recharge.</p>;
    }

    return (
        <Container className="quick-recharge-container py-5">
            <div className="quick-recharge-card">
                <div className="quick-recharge-card-body">
                    <h2 className="quick-recharge-h2 text-center mb-4">Quick Recharge</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formQuantity">
                            <Form.Label className="quick-recharge-label">Recharge Quantity</Form.Label>
                            <Form.Control
                                className="quick-recharge-input"
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                placeholder="Enter quantity"
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formPaymentAddress" className="mt-3">
                            <Form.Label className="quick-recharge-label">Payment Address</Form.Label>
                            <Form.Control
                                className="quick-recharge-input"
                                type="text"
                                value={paymentAddress}
                                onChange={(e) => setPaymentAddress(e.target.value)}
                                placeholder="Enter payment address"
                                required
                            />
                        </Form.Group>
                        <Button type="submit" variant="primary" className="quick-recharge-button mt-3">
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default QuickRecharge;
